import React from 'react';
import { Link } from 'react-router-dom';
import { IS_SOKO_APP, ROUTES } from '../../../utils/consts';
import UjamaLogo from '../../../assets/images/logo.svg';
import SokoLogo from '../../../assets/images/soko-logo.svg';

const Logo = () => {
  return (
    <h1 className="logo">
      <Link to={`${ROUTES.DASHBOARD}`}>
        <img src={IS_SOKO_APP ? SokoLogo : UjamaLogo} alt="Ujama" />
      </Link>
    </h1>
  );
};

export default Logo;
