import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import GilroyThin from './assets/fonts/Gilroy-Thin.otf';
import GilroyLight from './assets/fonts/Gilroy-Light.otf';
import GilroyRegular from './assets/fonts/Gilroy-Regular.otf';
import GilroyBold from './assets/fonts/Gilroy-Bold.otf';
import GilroyBlack from './assets/fonts/Gilroy-Black.otf';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2A9D8F', // Soko green
      contrastText: '#ffffff',
    },
    primaryText: {
      main: '#141414', // dark grey
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff4568', // accent red
    },
    secondaryText: {
      main: '#0000008a', // light grey
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Gilroy, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: 32,
      fontWeight: 600,
      textAlign: 'left',
      marginTop: 14,
      marginBottom: 14,
      '@media (max-width:600px)': {
        fontSize: 24,
      },
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      '@media (max-width:600px)': {
        fontSize: 18,
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 800,
      margin: 14,
      position: 'relative',
      '@media (max-width:600px)': {
        fontSize: 18,
      },
    },
    h4: {
      fontSize: 21,
      '@media (max-width:600px)': {
        fontSize: 15,
      },
    },
    h6: {
      fontSize: 16,
      '@media (max-width:600px)': {
        fontSize: 12,
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Gilroy',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 100,
            src: `url(${GilroyThin}) format('opentype')`,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
          },
          {
            fontFamily: 'Gilroy',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 200,
            src: `url(${GilroyLight}) format('opentype')`,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
          },
          {
            fontFamily: 'Gilroy',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${GilroyRegular}) format('opentype')`,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
          },
          {
            fontFamily: 'Gilroy',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 600,
            src: `url(${GilroyBold}) format('opentype')`,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
          },
          {
            fontFamily: 'Gilroy',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 800,
            src: `url(${GilroyBlack}) format('opentype')`,
            unicodeRange:
              'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
          },
        ],
      },
    },
    MuiToolbar: {
      root: {
        justifyContent: 'space-between',
        display: 'flex',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'white',
        color: '#141414',
      },
    },
    MuiTabs: {
      indicator: {
        background: '#01C6D9',
        height: 3,
        transform: 'translate(0, -5px)',
      },
    },
    MuiTab: {
      root: {
        '&:hover': {
          opacity: '0.4',
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        backgroundColor: '#01C6D9',
        transition:
          'color ease-in 0.5s, background-color ease-in-out 0.4s, box-shadow ease-in-out 0.3s',
        color: '#ffffff',
        '&:hover': {
          color: '#1D1D1D',
          backgroundColor: '#EEEEEE',
          boxShadow: '0px 3px 8px #00000010',
        },

        '@media (max-width:600px)': {
          margin: 7,
        },
      },
      colorSecondary: {
        transition: 'background-color ease-in 0.5s, box-shadow ease-in-out 0.3s',
        color: '#1D1D1D',
        '&:hover': {
          backgroundColor: '#fafafa',
          color: '#01C6D9',
          boxShadow: '0px 3px 8px #00000010',
        },
      },
      root: {
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: 24,
        padding: '10px 25px 9px',
        '&& svg': {
          margin: 7,
        },
      },
    },
    MuiCircularProgress: {
      root: {
        margin: 24,
        color: '#01C6D9',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 24,
        padding: '10px 24px',
        fontSize: 15,
      },
      multiline: {
        padding: '18px 24px',
      },
      notchedOutline: {
        borderWidth: 2,
      },
    },
    MuiFormControl: {
      root: {
        margin: '7px 14px',
      },
    },
    MuiAvatar: {
      root: {
        objectFit: 'cover',
        width: 64,
        height: 64,

        '@media (max-width:600px)': {
          width: 32,
          height: 32,
          marginLeft: 0,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#00000033',
      },
    },
    MuiCard: {
      root: {
        '&&.rounded': {
          borderRadius: 16,
        },
        '&&.bordered': {
          border: '1px solid #E8E8E8',
        },
        '&&.inverted': {
          backgroundColor: '#01C6D9',
          color: 'white',
        },
        '&&.padded': {
          margin: '0 14px 24px',
          padding: '14px 14px 24px',
        },
        '&&.centered': {
          textAlign: 'center',
        },
      },
    },
    MuiListItemText: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
  props: {
    MuiAppBar: {
      elevation: 0,
    },
    MuiPaper: {
      square: true,
      elevation: 0,
    },
    MuiCard: {
      elevation: 0,
    },
    MuiIconButton: {
      disableRipple: true,
      disableFocusRipple: true,
    },
  },
});

export default theme;
