import React, { lazy, Suspense, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { firebase } from './api/firebase/firebase';

import Layout from './components/layout';
import AuthGuard from './components/auth';
import SendBirdWrapper from './api/sendbird/sendbird-wrapper';
import URLHelper from './api/helpers/url-helper';

// route components (first-load)
import Dashboard from './components/private/dashboard/dashboard';
import Messages from './components/private/chat/messages';
import Marketplace from './components/private/marketplace/marketplace';
import MarketplaceViewer from './components/private/marketplace/marketplace-viewer';
import MarketplaceSettings from './components/private/marketplace/marketplace-settings';
import MyProfile from './components/private/my-profile/my-profile';
import CommunityPortal from './components/private/community-portal/community-portal/community-portal';

// route components (lazy-loaded)
const Login = lazy(() => import('./components/public/login/login'));
const Logout = lazy(() => import('./components/public/login/logout'));
const LoginRedirect = lazy(() => import('./components/public/login/login-redirect'));
const SMSLanding = lazy(() => import('./components/public/sms-landing'));
const CreateProfile = lazy(() => import('./components/private/my-profile/create-profile'));
const Welcome = lazy(() => import('./components/private/my-profile/welcome'));
const PhoneAuth = lazy(() => import('./components/private/my-profile/phone-auth'));
const NotFound = lazy(() => import('./components/public/not-found/not-found'));
const FindParents = lazy(() => import('./components/private/find-parents/find-parents'));
const FindContacts = lazy(() =>
  import('./components/private/dashboard/find-contacts/find-contacts'),
);
const ContactProfile = lazy(() => import('./components/private/contact-profile/contact-profile'));
const ParentProfile = lazy(() => import('./components/private/parent-profile/parent-profile'));
const SchoolProfile = lazy(() => import('./components/private/school-profile/school-profile'));
const ActivityProfile = lazy(() =>
  import('./components/private/activity-profile/activity-profile'),
);
const KidProfile = lazy(() => import('./components/private/kid-profile/kid-profile'));
const KidViewer = lazy(() => import('./components/private/kid-viewer/kid-viewer'));
const Chat = lazy(() => import('./components/private/chat/chat'));
const ChatCheckout = lazy(() => import('./components/private/chat/widgets/common/checkout'));
const ChatCheckoutPay = lazy(() => import('./components/private/chat/widgets/common/payment'));
const StripePaymentsSuccess = lazy(() =>
  import('./components/private/chat/widgets/common/stripe-payments-success'),
);
const StripePaymentsFailure = lazy(() =>
  import('./components/private/chat/widgets/common/stripe-payments-failure'),
);
const StripePaymentsCheckout = lazy(() =>
  import('./components/private/chat/widgets/common/stripe-payments-checkout'),
);

const StripeNative = lazy(() => import('./components/public/stripe/stripe-native'));
const StripeNativePaymentSuccessSoko = lazy(() =>
  import('./components/public/stripe/stripe-payment-success-soko'),
);

const InstantCharge = lazy(() => import('./components/public/instant-charge/instant-charge'));
const PublicMarketsDirectory = lazy(() => import('./components/public/markets/directory'));
const PublicMarketViewer = lazy(() => import('./components/public/markets/viewer'));

const ForSaleInvoice = lazy(() => import('./components/private/chat/widgets/common/invoice'));
const ForSaleOrder = lazy(() => import('./components/private/chat/widgets/common/order'));
const OfflinePayment = lazy(() =>
  import('./components/private/chat/widgets/common/offline-payment'),
);

const RideDetail = lazy(() => import('./components/common/pages/ride-detail'));
const KidSelect = lazy(() => import('./components/common/pages/kid-select'));
const PhotoGallery = lazy(() => import('./components/common/pages/photo-gallery'));

const PeriodsSelect = lazy(() => import('./components/private/periods-select/periods-select'));
const AddSchool = lazy(() => import('./components/private/community-portal/add-school/add-school'));
const ListSchools = lazy(() =>
  import('./components/private/community-portal/school-list/school-list'),
);
const ParentsList = lazy(() =>
  import('./components/private/community-portal/parents-list/parents-list'),
);
const FindSchoolsList = lazy(() =>
  import('./components/private/community-portal/find-schools-list/find-schools-list'),
);
const ListOrganizations = lazy(() =>
  import('./components/private/community-portal/activity-organizations/organizations-list'),
);
const ListMarkets = lazy(() => import('./components/private/community-portal/markets/market-list'));
const MarketEdit = lazy(() => import('./components/private/community-portal/markets/market-edit'));
const MailManager = lazy(() => import('./components/private/community-portal/mail-manager'));
const Analytics = lazy(() => import('./components/private/community-portal/analytics'));
const TransactionReport = lazy(() =>
  import('./components/private/community-portal/transaction-report'),
);
const AddOrganization = lazy(() =>
  import('./components/private/community-portal/add-organization/add-organization'),
);
const ContactPortal = lazy(() => import('./components/private/contact-portal/contact-portal'));
const FindOrganizations = lazy(() =>
  import('./components/private/find-organizations/find-organizations'),
);
const RequestsPanel = lazy(() => import('./components/private/requests-panel/requests-panel'));
const ActivityLocationList = lazy(() => import('./components/common/pages/activity-location-list'));
const SessionsList = lazy(() => import('./components/common/pages/sessions-list'));
const SessionEdit = lazy(() => import('./components/common/pages/session-edit'));
const ActivityClassList = lazy(() => import('./components/common/pages/activity-class-list'));
const Grades = lazy(() => import('./components/common/pages/grades'));
const Holidays = lazy(() => import('./components/common/pages/holidays'));
const HolidayEdit = lazy(() => import('./components/common/pages/holiday-edit'));
const AltTimings = lazy(() => import('./components/common/pages/alt-timings'));
const AltEdit = lazy(() => import('./components/common/pages/alt-edit'));
const PeriodsList = lazy(() => import('./components/common/pages/periods'));
const BellScheduleList = lazy(() => import('./components/common/pages/bell-list'));
const EditBell = lazy(() => import('./components/common/pages/edit-bell'));
const SpecialList = lazy(() => import('./components/common/pages/special-list'));
const EditSpecial = lazy(() => import('./components/common/pages/edit-special'));
const BellPeriods = lazy(() => import('./components/common/pages/bell-periods'));
const LocationEdit = lazy(() => import('./components/common/pages/location-edit'));
const ActivityClassEdit = lazy(() => import('./components/common/pages/activity-class-edit'));
const AddressPage = lazy(() => import('./components/common/pages/address-page'));
const ActivityOther = lazy(() => import('./components/common/pages/activity'));
const OptionsSelect = lazy(() => import('./components/common/pages/options'));
const DualTimeEntry = lazy(() => import('./components/common/pages/dual-entry'));
const CustomNotificationTemplate = lazy(() =>
  import('./components/common/pages/custom-notification-template'),
);
const AddKidChat = lazy(() => import('./components/private/chat-bot/add-kid-chat'));
const AddMeetupChat = lazy(() => import('./components/private/chat-bot/add-meetup-chat'));
const PlaydateChat = lazy(() => import('./components/private/chat-bot/playdate-chat'));
const RideChat = lazy(() => import('./components/private/chat-bot/ride-chat'));
const BabysitterChat = lazy(() => import('./components/private/chat-bot/babysitter-chat'));
const OfferRideChat = lazy(() => import('./components/private/chat-bot/offer-ride-chat'));

const VerifyParents = lazy(() => import('./components/private/verify-parents/verify-parents'));
const CalendarLogs = lazy(() => import('./components/private/calendar-logs/calendar-logs'));
const PromotionList = lazy(() =>
  import('./components/private/community-portal/promotions/promotion-list'),
);
const PromotionEdit = lazy(() =>
  import('./components/private/community-portal/promotions/promotion-edit'),
);
const PartnerList = lazy(() =>
  import('./components/private/community-portal/partners/partner-list'),
);
const PartnerEdit = lazy(() =>
  import('./components/private/community-portal/partners/partner-edit'),
);
const InvitationLink = lazy(() => import('./components/public/redirect/redirect'));
const NewChatList = lazy(() => import('./components/private/new-chat/new-chat'));
const NewGroupChat = lazy(() => import('./components/private/new-chat/new-group-chat'));
const AdminSearchProfiles = lazy(() =>
  import('./components/private/community-portal/admin-search-profiles'),
);
const AdminShowMembershipLists = lazy(() =>
  import('./components/private/community-portal/show-membership-lists'),
);
const AdminSendbirdMigration = lazy(() =>
  import('./components/private/community-portal/sendbird-migration'),
);
const DuplicateSchool = lazy(() =>
  import('./components/private/community-portal/dupe-school-list/dupe-school-list'),
);
const DuplicateOrganization = lazy(() =>
  import('./components/private/community-portal/dupe-org-list/dupe-org-list'),
);
const FixDuplicate = lazy(() =>
  import('./components/private/community-portal/dupe-profile/dupe-profile'),
);
const FixDuplicateList = lazy(() =>
  import('./components/private/community-portal/dupe-profile/dupe-profile-list'),
);
const DuplicateProfile = lazy(() =>
  import('./components/private/community-portal/dupe-parent-list/dupe-parent-list'),
);
const HolidayPage = lazy(() => import('./components/common/pages/happy-holidays.js'));
const ShowMap = lazy(() => import('./components/common/pages/show-map.js'));
const MessageSubscriptionManagement = lazy(() =>
  import('./components/common/message-subscription/message-subscription-management'),
);
const PartnerLanding = lazy(() => import('./components/private/landing/partner'));
const SchoolLanding = lazy(() => import('./components/private/landing/school'));
const ActivityLanding = lazy(() => import('./components/private/landing/activity'));
const NeighborhoodLanding = lazy(() => import('./components/private/landing/neighborhood'));
const ShowAllCommunities = lazy(() => import('./components/private/landing/show-all'));

const PartnerViewer = lazy(() => import('./components/private/partner/partner-viewer'));
const ChapterList = lazy(() => import('./components/private/partner/chapter-list'));
const ChapterEdit = lazy(() => import('./components/private/partner/chapter-edit'));

const SocialPost = lazy(() => import('./components/common/social/social-post'));
const VideoChat = lazy(() => import('./components/private/video/video-chat'));

const AvailabilityPoll = lazy(() => import('./components/private/chat/widgets/creators/poll'));
const SignupForm = lazy(() => import('./components/private/chat/widgets/creators/signup-form'));
const EventCreator = lazy(() => import('./components/private/chat/widgets/creators/event'));
const WebinarCreator = lazy(() => import('./components/private/chat/widgets/creators/webinar'));
const CollectMoneyCreator = lazy(() =>
  import('./components/private/chat/widgets/creators/collect-money'),
);
const GenericRequest = lazy(() =>
  import('./components/private/chat/widgets/creators/generic-request'),
);
const ForSaleCreator = lazy(() => import('./components/private/chat/widgets/creators/for-sale'));

const BranchIORouter = lazy(() => import('./components/private/branchio-router'));

const Vendors = lazy(() => import('./components/private/vendors'));
const Favorites = lazy(() => import('./components/private/favorites'));
const EditVendor = lazy(() => import('./components/private/edit-vendor'));
const AssignMarketsToVendor = lazy(() => import('./components/private/assign-markets-to-vendor'));

const EBTPinPad = lazy(() => import('./components/public/ebt/ebt-pin-pad'));

const MarketsReport = lazy(() => import('./components/private/community-portal/markets-report'));

const GenerateStaticPages = lazy(() =>
  import('./components/private/community-portal/generate-static-pages'),
);

const RoutePrivate = ({ component: Component, ...rest }) => {
  const [currentUser, setCurrentUser] = useState(null);
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          // authentication check
          <AuthGuard currentUser={currentUser} setCurrentUser={setCurrentUser}>
            {!Component ? (
              <NotFound />
            ) : (
              // sendbird initialization and main layout wrapping
              <SendBirdWrapper>
                <Layout>
                  <Component {...props} />
                </Layout>
              </SendBirdWrapper>
            )}
          </AuthGuard>
        );
      }}
    />
  );
};

// Use when you want to different places depending on whether the user is logged in or not
class RouteConditional extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      waiting: true,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ user, waiting: false });
    });
  }

  render() {
    const { waiting, user } = this.state;
    const { component, publicComponent } = this.props;

    if (waiting) {
      return null;
    }

    if (user) {
      return <RoutePrivate component={component} {...this.props} />;
    }

    return <Route {...this.props} component={publicComponent} />;
  }
}

const preloader = (
  <CircularProgress
    style={{
      width: 100,
      height: 100,
      position: 'absolute',
      left: '50%',
      top: '50%',
      margin: '-50px 0 0 -50px',
    }}
  />
);

// save any incoming query string
URLHelper.save(window.location.search);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uncaught_error: false,
    };
  }
  static getDerivedStateFromError(error) {
    return { uncaught_error: true };
  }

  componentDidUpdate() {
    // reset scroll when loading
    document.getElementsByTagName('html')[0].scrollTop = 0;
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.info(info);

    this.setState({ uncaught_error: false });
  }

  render() {
    return this.state.uncaught_error ? (
      preloader
    ) : (
      <Suspense fallback={preloader}>
        <Switch>
          <RoutePrivate exact path="/" component={Dashboard} />

          <Route exact path="/mobile" component={InvitationLink} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/login-redirect" component={LoginRedirect} />
          <Route exact path="/create-profile" component={CreateProfile} />
          <Route exact path="/welcome" component={Welcome} />

          <RouteConditional
            exact
            path="/textMeApp"
            publicComponent={SMSLanding}
            component={Dashboard}
          />
          <RoutePrivate exact path="/branchio-router" component={BranchIORouter} />

          <RoutePrivate exact path="/vendors" component={Vendors} />
          <RoutePrivate exact path="/favorites" component={Favorites} />
          <RoutePrivate exact path="/edit-vendor" component={EditVendor} />
          <RoutePrivate exact path="/assign-markets-to-vendor" component={AssignMarketsToVendor} />

          <Route exact path="/subscribe" component={MessageSubscriptionManagement} />

          <RoutePrivate exact path="/marketplace" component={Marketplace} />
          <RoutePrivate exact path="/marketplace-viewer" component={MarketplaceViewer} />
          <RoutePrivate exact path="/marketplace-settings" component={MarketplaceSettings} />

          <RoutePrivate exact path="/messages" component={Messages} />
          <RoutePrivate exact path="/messages/chat" component={Chat} />
          <RoutePrivate exact path="/messages/chat/checkout" component={ChatCheckout} />
          <RoutePrivate exact path="/messages/chat/checkout/pay" component={ChatCheckoutPay} />
          <Route exact path="/stripe-payments-success" component={StripePaymentsSuccess} />
          <Route exact path="/stripe-payments-failure" component={StripePaymentsFailure} />
          <Route exact path="/stripe-go-to-checkout" component={StripePaymentsCheckout} />
          <Route exact path="/stripe-native" component={StripeNative} />
          <Route exact path="/stripe-payment-success" component={StripeNativePaymentSuccessSoko} />

          <Route exact path="/instant-charge" component={InstantCharge} />
          <Route exact path="/markets" component={PublicMarketsDirectory} />
          <Route exact path="/market/:id" component={PublicMarketViewer} />
          <RoutePrivate exact path="/market-private/:id" component={PublicMarketViewer} />

          <RoutePrivate exact path="/messages/chat/invoice" component={ForSaleInvoice} />
          <RoutePrivate exact path="/messages/chat/order" component={ForSaleOrder} />
          <RoutePrivate exact path="/messages/chat/pay-offline" component={OfflinePayment} />

          <RoutePrivate exact path="/messages/new-chat" component={NewChatList} />
          <RoutePrivate exact path="/messages/new-group-chat" component={NewGroupChat} />
          <RoutePrivate exact path="/add-kid" component={AddKidChat} />
          <RoutePrivate exact path="/add-meetup" component={AddMeetupChat} />
          <RoutePrivate exact path="/add-playdate" component={PlaydateChat} />
          <RoutePrivate exact path="/ask-for-ride" component={RideChat} />
          <RoutePrivate exact path="/ask-for-babysitter" component={BabysitterChat} />
          <RoutePrivate exact path="/offer-ride" component={OfferRideChat} />

          <RoutePrivate exact path="/partner-landing" component={PartnerLanding} />
          <RoutePrivate exact path="/school-landing" component={SchoolLanding} />
          <RoutePrivate exact path="/activity-landing" component={ActivityLanding} />
          <RoutePrivate exact path="/neighborhood-landing" component={NeighborhoodLanding} />
          <RoutePrivate exact path="/show-all-communities" component={ShowAllCommunities} />

          <RoutePrivate exact path="/partner-viewer" component={PartnerViewer} />
          <RoutePrivate exact path="/chapter-list" component={ChapterList} />
          <RoutePrivate exact path="/chapter-edit" component={ChapterEdit} />

          <RoutePrivate exact path="/social-post" component={SocialPost} />
          <RoutePrivate exact path="/video-chat" component={VideoChat} />

          <RoutePrivate path="/my-profile" component={MyProfile} />
          <RoutePrivate path="/phone-auth" component={PhoneAuth} />
          <RoutePrivate path="/find-parents" component={FindParents} />
          <RoutePrivate path="/verify-parents" component={VerifyParents} />
          <RoutePrivate path="/find-contacts" component={FindContacts} />
          <RoutePrivate path="/find-organizations" component={FindOrganizations} />
          <RoutePrivate path="/find-schools-list" component={FindSchoolsList} />
          <RoutePrivate path="/contact/*" component={ContactProfile} />
          <RoutePrivate path="/parent/:id" component={ParentProfile} />
          <RoutePrivate path="/school/:id" component={SchoolProfile} />
          <RoutePrivate path="/activity/:id" component={ActivityProfile} />
          <RoutePrivate path="/kid/:id" component={KidProfile} />
          <RoutePrivate path="/kid-viewer/:id" component={KidViewer} />
          <RoutePrivate path="/periods-select" component={PeriodsSelect} />
          <RoutePrivate path="/options" component={OptionsSelect} />
          <RoutePrivate path="/dual-entry" component={DualTimeEntry} />
          <RoutePrivate path="/ride-detail" component={RideDetail} />
          <RoutePrivate path="/kid-select" component={KidSelect} />
          <RoutePrivate path="/photo-gallery" component={PhotoGallery} />
          <RoutePrivate
            path="/custom-notification-template"
            component={CustomNotificationTemplate}
          />
          <RoutePrivate path="/contact-portal" component={ContactPortal} />
          <RoutePrivate path="/calendar-logs" component={CalendarLogs} />
          <RoutePrivate path="/fix-duplicates" component={DuplicateSchool} />
          <RoutePrivate path="/fix-duplicate-org" component={DuplicateOrganization} />
          <RoutePrivate path="/merge-profiles" component={DuplicateProfile} />
          <RoutePrivate path="/duplicate-profile" component={FixDuplicate} />
          <RoutePrivate path="/duplicate-profile-list" component={FixDuplicateList} />

          <RoutePrivate exact path="/community-portal" component={CommunityPortal} />
          <RoutePrivate path="/community-portal/requests-panel" component={RequestsPanel} />
          <RoutePrivate path="/community-portal/parents-list" component={ParentsList} />
          <RoutePrivate exact path="/community-portal/add-school/:id" component={AddSchool} />
          <RoutePrivate
            exact
            path="/community-portal/add-organization/:id"
            component={AddOrganization}
          />
          <RoutePrivate
            exact
            path="/community-portal/add-school/:id/add-grade"
            component={Grades}
          />
          <RoutePrivate exact path="/community-portal/holidays" component={Holidays} />
          <RoutePrivate exact path="/community-portal/holidays/edit" component={HolidayEdit} />
          <RoutePrivate exact path="/community-portal/alt-list" component={AltTimings} />
          <RoutePrivate exact path="/community-portal/alt-list/edit" component={AltEdit} />
          <RoutePrivate exact path="/community-portal/periods" component={PeriodsList} />
          <RoutePrivate exact path="/community-portal/bells" component={BellScheduleList} />
          <RoutePrivate exact path="/community-portal/bells/edit" component={EditBell} />
          <RoutePrivate exact path="/community-portal/bells/edit/periods" component={BellPeriods} />
          <RoutePrivate exact path="/community-portal/special-days" component={SpecialList} />
          <RoutePrivate exact path="/community-portal/special-days/edit" component={EditSpecial} />
          <RoutePrivate path="/address" component={AddressPage} />
          <RoutePrivate path="/community-portal/list-schools" component={ListSchools} />
          <RoutePrivate path="/community-portal/list-organizations" component={ListOrganizations} />
          <RoutePrivate path="/community-portal/list-markets" component={ListMarkets} />
          <RoutePrivate path="/community-portal/market-edit" component={MarketEdit} />
          <RoutePrivate
            path="/community-portal/activity-location-list"
            component={ActivityLocationList}
          />
          <RoutePrivate path="/community-portal/sessions-list" component={SessionsList} />
          <RoutePrivate path="/community-portal/activity" component={ActivityOther} />
          <RoutePrivate path="/community-portal/session-edit" component={SessionEdit} />
          <RoutePrivate
            path="/community-portal/activity-class-list"
            component={ActivityClassList}
          />
          <RoutePrivate path="/community-portal/location-edit" component={LocationEdit} />
          <RoutePrivate
            path="/community-portal/activity-class-edit"
            component={ActivityClassEdit}
          />
          <RoutePrivate path="/community-portal/mail-manager" component={MailManager} />
          <RoutePrivate path="/community-portal/analytics" component={Analytics} />
          <RoutePrivate path="/community-portal/markets-report" component={MarketsReport} />
          <RoutePrivate path="/community-portal/transaction-report" component={TransactionReport} />
          <RoutePrivate path="/community-portal/promotion-list" component={PromotionList} />
          <RoutePrivate path="/community-portal/promotion-edit" component={PromotionEdit} />
          <RoutePrivate path="/community-portal/partner-list" component={PartnerList} />
          <RoutePrivate path="/community-portal/partner-edit" component={PartnerEdit} />
          <RoutePrivate
            path="/community-portal/admin-search-profiles"
            component={AdminSearchProfiles}
          />
          <RoutePrivate
            path="/community-portal/show-membership-lists"
            component={AdminShowMembershipLists}
          />
          <RoutePrivate
            path="/community-portal/sendbird-migration"
            component={AdminSendbirdMigration}
          />
          <RoutePrivate
            path="/community-portal/generate-static-pages"
            component={GenerateStaticPages}
          />
          <RoutePrivate path="/show-map" component={ShowMap} />
          <RoutePrivate path="/happy-holidays" component={HolidayPage} />

          <RoutePrivate path="/availability-poll" component={AvailabilityPoll} />
          <RoutePrivate path="/signup-form" component={SignupForm} />
          <RoutePrivate path="/event-creator" component={EventCreator} />
          <RoutePrivate path="/webinar-creator" component={WebinarCreator} />
          <RoutePrivate path="/collect-money-creator" component={CollectMoneyCreator} />
          <RoutePrivate path="/generic-request" component={GenericRequest} />
          <RoutePrivate path="/for-sale-creator" component={ForSaleCreator} />

          <Route path="/ebt-pin-pad" component={EBTPinPad} />

          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(App);
