import FirebaseManager from '../firebase/firebase-manager';

export default class VendorStorage {
  static Read(vendorID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('vendors/' + vendorID, (snap) => {
      const vendor = snap && snap.exists() ? snap.val() : null;
      if (vendor) {
        vendor.id = snap.key;
      }
      callback(vendor);
    });
  }

  static ReadList(callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('vendors', (snap) => {
      const vendorList = [];

      if (snap) {
        snap.forEach((child) => {
          const vendor = child.val();
          if (vendor) {
            vendor.id = child.key;
            vendorList.push(vendor);
          }
        });
      }

      callback(vendorList);
    });
  }

  static Add(vendor) {
    const firebaseManager = new FirebaseManager();
    const today = new Date();

    vendor.created = today.toISOString();

    return firebaseManager.saveData('vendors/', vendor);
  }

  static Update(vendor, vendorID) {
    if (!vendorID) {
      console.error('Updating vendor with invalid vendor ID', vendor);
    }

    const today = new Date();
    const firebaseManager = new FirebaseManager();

    vendor.lastEdit = today.toISOString();
    vendor.lastEditBy = ''; // TODO - get current user

    return firebaseManager.update('vendors/' + vendorID, vendor);
  }

  static Delete(vendorID) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.deleteValue('vendors/' + vendorID);
  }

  static StoreImage(file) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();
      const now = new Date();
      const id = `vendor-${now.toISOString()}`;

      try {
        firebaseManager.storeImageFromFile(`vendors/${id}`, file, (imageSource) => {
          resolve(imageSource);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
