import branch from 'branch-sdk';

// Handle the deep links clicked from inside Ujama
export function openDeepLink(link) {
  branch.openURL(link);
}

export function makeDeepLink(userID, feature, instructions) {
  return new Promise((resolve, reject) => {
    branch.init(process.env.REACT_APP_BRANCH_IO_API_KEY);

    const parameters = {
      channel: 'direct',
      feature: feature,
      data: {
        $referrer_id: instructions.referrerID,
        $desktop_url: `${process.env.REACT_APP_PUBLIC_URL}textmeapp`,
        $ios_url: `${process.env.REACT_APP_PUBLIC_URL}mobile`,
        $android_url: `${process.env.REACT_APP_PUBLIC_URL}mobile`,
        $og_type: 'website',
        $og_title: instructions.title,
        $og_description: instructions.description,
        $og_image_url: instructions.imageURL,
        $custom_meta_tags: {
          'fb:app_id': process.env.REACT_APP_FACEBOOK_APP_ID,
        },
        $chatURL: instructions.chatURL,
        $messageURL: instructions.messageURL,
        $entityId: instructions.entityId,
        $entityName: instructions.entityName,
        partner: instructions.partner,
        vendorId: instructions.vendorId,
        marketId: instructions.marketId,
        amountCharged: instructions.amountCharged,
        currencty: instructions.currency,
        paymentItentId: instructions.paymentIntentId,
        paymentIntentSecret: instructions.paymentIntentSecret,
        customerName: instructions.customerName,
        customerEmail: instructions.customerEmail,
      },
    };

    branch.link(parameters, (error, link) => {
      if (error) {
        reject(error);
      } else {
        resolve(link);
      }
    });
  });
}
