class CalculationsHelper {
  static calculateDistance(coordinates1, coordinates2) {
    let R = 6371; // Radius of the earth in km

    let dLat = deg2rad(coordinates2.latitude - coordinates1.latitude);
    let dLon = deg2rad(coordinates2.longitude - coordinates1.longitude);

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(coordinates1.latitude)) *
        Math.cos(deg2rad(coordinates2.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;

    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }
  }

  static calculateTravelTime(address1, address2) {
    return new Promise((resolve, reject) => {
      const time = new Date();

      try {
        getTravelTime(address1, address2, time, (duration) => {
          resolve(duration || null);
        });
      } catch (e) {
        console.error(e);
        reject();
      }
    });

    function getTravelTime(fromAddress, toAddress, arrivalTime, callback) {
      if (!fromAddress || !fromAddress[0] || !toAddress || !toAddress[0]) {
        callback(null);
        return;
      }

      if (!google || !google.maps) {
        console.error('Calculations: Google Maps API has not been initialized.');
        return null;
      }
      const matrix = new google.maps.DistanceMatrixService();
      const distanceData = {
        origins: [fromAddress],
        destinations: [toAddress],
        travelMode: google.maps.TravelMode.DRIVING,
      };

      matrix.getDistanceMatrix(distanceData, function (res, status) {
        if (status === 'OK') {
          const duration = res.rows[0].elements[0].duration;
          callback(duration ? duration.text : null);
        } else {
          callback(null);
        }
      });
    }
  }
}

export default CalculationsHelper;
