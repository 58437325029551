import React from 'react';
import moment from 'moment';
import { Card, CardHeader, CircularProgress, Divider, ListItem } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { toast } from 'react-toastify';

import UjamaAppBar from '../../../common/material-wrap/app-bar';
import './community-portal.css';
import ROUTES from '../../../../utils/consts';
import {
  exportDataToCSV,
  exportOrganizationList,
  exportSchoolList,
} from '../../../../api/helpers/exporters';
import RolesStorage from '../../../../api/storage/roles';
import VendorStorage from '../../../../api/storage/vendor';
import MarketStorage from '../../../../api/storage/markets';
import PushNotification from '../../../../api/pubnub/push-notification';
import SendBirdUser from '../../../../api/sendbird/sendbird-user';

class CommunityPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showProgress: false, isLoading: true };
  }

  componentDidMount() {
    RolesStorage.CheckSuperUser((adminStatus) => {
      this.setState({
        adminStatus,
        isLoading: false,
      });
    });
  }

  routeClick(route, state) {
    const location = {
      pathname: route,
      state: state,
    };
    this.props.history.push(location);
  }

  onClickMailManager() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.MAIL_MANAGER);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onClickExportSchoolList() {
    if (this.state.adminStatus === 'ujama') {
      this.setState({ showProgress: true });
      exportSchoolList((list) => {
        exportDataToCSV(list);
        this.setState({ showProgress: false });
      });
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onClickExportOrgList() {
    if (this.state.adminStatus === 'ujama') {
      this.setState({ showProgress: true });
      exportOrganizationList((list) => {
        exportDataToCSV(list);
        this.setState({ showProgress: false });
      });
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onClickAnalytics() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.ANALYTICS);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onClickMarketData() {
    this.routeClick(ROUTES.MARKETS_REPORT);
  }

  onClickTransactions() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.TRANSACTION_REPORT);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onClickRequestsPanel() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.REQUESTS_PANEL);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onClickPromotions() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.MANAGE_PROMOTIONS);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onClickPartners() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.MANAGE_PARTNERS, this.state);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onSearchProfiles() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.ADMIN_SEARCH_PROFILES);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onShowMembershipLists() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.ADMIN_SHOW_MEMBERSHIP_LISTS);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onGenerateStaticPages() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.ADMIN_GENERATE_STATIC_PAGES);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  onSendbirdMigration() {
    if (this.state.adminStatus === 'ujama') {
      this.routeClick(ROUTES.ADMIN_SENDBIRD_MIGRATION);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  sendPushNotificationToMyself(isIosDebugMode) {
    if (this.state.adminStatus === 'ujama') {
      const userId = localStorage.getItem('userId');
      PushNotification.SendNotification('Test Notification from Web', userId, null, isIosDebugMode);
    } else {
      toast.error('Sorry you do not have permission to access that function.');
    }
  }

  addVendorIDToGroupChats() {
    if (this.state.adminStatus !== 'ujama') {
      toast.error('Sorry you do not have permission to access that function.');
    } else {
      VendorStorage.ReadList((vendorList) => {
        vendorList.forEach((vendor) => {
          if (vendor.marketplaceChannelURL) {
            SendBirdUser.GetChannel(vendor.marketplaceChannelURL, (channel) => {
              if (channel) {
                SendBirdUser.ChannelAddMetaData(
                  channel,
                  { vendorId: vendor.id },
                  (success, error) => {
                    if (!success) {
                      console.warn('Unable to add vendor metadata to channel', vendor, error);
                    } else {
                      console.log(`Successfully added ${vendor.id} to ${channel.url}`);
                    }
                  },
                );
              } else {
                console.warn('Unable to find channel', vendor.marketplaceChannelURL);
              }
            });
          }
        });
        window.alert('Requests sent!');
      });
    }
  }

  // Never save UTC date strings in the database
  // Fixed this problem
  fixupMarketDates() {
    if (this.state.adminStatus !== 'ujama') {
      toast.error('Sorry you do not have permission to access that function.');
    } else {
      const promises = [];

      MarketStorage.ReadAll((markets) => {
        markets.forEach((market) => {
          let needsSaving = false;
          if (market.timings) {
            if (market.timings.days) {
              market.timings.days.forEach((day) => {
                if (day.from.includes('Z')) {
                  day.from = moment(day.from).format('h:mm A');
                  needsSaving = true;
                }
                if (day.to.includes('Z')) {
                  day.to = moment(day.to).format('h:mm A');
                  needsSaving = true;
                }
              });
            }
            if (market.timings.specific_days) {
              market.timings.specific_days.forEach((day) => {
                if (day.date.includes('Z')) {
                  day.date = moment(day.date).format('MM/DD/YYYY');
                  needsSaving = true;
                }
                if (day.startTime.includes('Z')) {
                  day.startTime = moment(day.startTime).format('h:mm A');
                  needsSaving = true;
                }
                if (day.endTime.includes('Z')) {
                  day.endTime = moment(day.endTime).format('h:mm A');
                  needsSaving = true;
                }
              });
            }
            if (market.timings.irregular) {
              const time = market.timings.irregular.time;
              if (time.from.includes('Z')) {
                time.from = moment(time.from).format('h:mm A');
                needsSaving = true;
              }
              if (time.to.includes('Z')) {
                time.to = moment(time.to).format('h:mm A');
                needsSaving = true;
              }
            }
          }
          if (needsSaving) {
            console.log(`Need to update ${market.name}`, market.timings);
            promises.push(
              new Promise((resolve) => {
                MarketStorage.Update(market, market.id).then(() => resolve());
              }),
            );
          }
        });
      });

      const numberOfMarkets = promises.length;
      Promise.all(promises).then(() => {
        toast(`Updated ${numberOfMarkets} markets`);
      });
    }
  }

  render() {
    return (
      <div>
        <UjamaAppBar type={'time'} title={'Community Portal'} hideGoBack={true} />
        {this.state.isLoading ? this.renderLoading() : this.renderScreen()}
      </div>
    );
  }

  renderLoading() {
    return (
      <section className="screen-holder">
        <CircularProgress />
      </section>
    );
  }

  renderScreen() {
    if (this.state.adminStatus === 'ujama') {
      return (
        <section className="screen-holder">
          {this.renderGlobalLists()}
          {this.renderAdministrativeFunctions()}
          {this.renderProgress()}
        </section>
      );
    } else {
      return (
        <section className="screen-holder">
          {this.renderGlobalLists()}
          {this.renderProgress()}
        </section>
      );
    }
  }

  renderGlobalLists() {
    return (
      <div>
        <Card>
          <CardHeader
            style={styles.cardHeader}
            titleTypographyProps={{ color: 'textSecondary', variant: 'h4' }}
            title={'Global lists'}
          />

          <ListItem
            button
            style={styles.listLeft}
            onClick={() => this.routeClick(ROUTES.PARENTS_LIST, { all: true })}
          >
            Members
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />

          <ListItem
            button
            style={styles.listLeft}
            onClick={() => this.routeClick(ROUTES.SCHOOL_LIST, { title: 'List Schools' })}
          >
            Schools
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />

          <ListItem
            button
            style={styles.listLeft}
            onClick={() => this.routeClick(ROUTES.ORGANIZATION_LIST, { findOrgs: false })}
          >
            Activity Organizations
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />

          <ListItem
            button
            style={styles.listLeft}
            onClick={() =>
              this.routeClick(ROUTES.FIND_PARENTS, {
                marketplaceId: '*',
                title: 'Ujama Sellers',
              })
            }
          >
            Sellers
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
        </Card>
        <div style={{ height: 5 }} />
      </div>
    );
  }

  renderAdministrativeFunctions() {
    const isNotDevMode = process.env.NODE_ENV === 'development';
    const isProd = process.env.REACT_APP_ENV_NAME === 'production';

    return (
      <div>
        <Card>
          <CardHeader
            style={styles.cardHeader}
            titleTypographyProps={{ color: 'textSecondary', variant: 'h4' }}
            title={'Administrative functions'}
          />
          <Divider />
          <ListItem
            button
            style={styles.listLeft}
            onClick={() => this.routeClick(ROUTES.MARKET_LIST)}
          >
            Markets
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickMailManager()}>
            Mail Manager
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickExportSchoolList()}>
            Export School List
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickExportOrgList()}>
            Export Organization List
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickAnalytics()}>
            Analytics
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickMarketData()}>
            Market Data
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickTransactions()}>
            Transactions
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickRequestsPanel()}>
            Requests Panel
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickPromotions()}>
            Manage Promotions
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onClickPartners()}>
            Manage Partners
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onSearchProfiles()}>
            Search Profiles
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onShowMembershipLists()}>
            Show Membership Lists
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onGenerateStaticPages()}>
            Generate Static Market Pages
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.onSendbirdMigration()}>
            Sendbird Migration Utility
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem
            button
            style={styles.listLeft}
            onClick={() => this.sendPushNotificationToMyself(false)}
          >
            {`Test Mobile Push Notification to my phone ${
              isNotDevMode && isProd ? '' : '(iOS Release Mode - Android any mode)'
            }`}
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          {isNotDevMode && isProd && (
            <>
              <Divider />
              <ListItem
                button
                style={styles.listLeft}
                onClick={() => this.sendPushNotificationToMyself(true)}
              >
                {'Test Mobile Push Notification to my phone (iOS Debug Mode - Android any mode)'}
                <span style={styles.listRight}>
                  <ChevronRight />
                </span>
              </ListItem>
            </>
          )}
          <Divider />
          <ListItem
            button
            style={styles.listLeft}
            onClick={() => this.addVendorIDToGroupChats(true)}
          >
            {'Add Vendor IDs to Vendor Group Chats'}
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
          <Divider />
          <ListItem button style={styles.listLeft} onClick={() => this.fixupMarketDates()}>
            {'Fixup Market Dates'}
            <span style={styles.listRight}>
              <ChevronRight />
            </span>
          </ListItem>
        </Card>
        <div style={{ height: 5 }} />
      </div>
    );
  }

  renderProgress() {
    if (this.state.showProgress) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }
  }
}

const styles = {
  cardHeader: {
    textAlign: 'left',
    fontWeight: 100,
  },
  listRight: {
    color: '#6d6d72',
    width: '100%',
    textAlign: 'right',
    margin: '0px',
    top: '12px',
    paddingRight: '10px',
    verticalAlign: 'super',
  },
  listLeft: {
    textAlign: 'left',
  },
};

export default CommunityPortal;
