import { withRouter } from 'react-router';
import _ from 'lodash';

import ProfileStorage from '../storage/profile';
import KidStorage from '../storage/kid';
import SchoolStorage from '../storage/school';
import OrganizationStorage from '../storage/organization';

import AuthHelper from '../helpers/auth';
import CalculationsHelper from '../../utils/calculations';

import ProfileDefault from '../../assets/images/profile-default.png';
import { encodeEmailForFirebaseKey, unformatPhoneNumber } from '../../api/helpers/formatterHelper';
//import NotificationStorage from '../../api/storage/notification';
import PartnerStorage from '../../api/storage/partner';
import ROUTES from '../../utils/consts';
import { firebase } from '../firebase/firebase';

class ProfileHelper {
  static getProfileDetails(profileId) {
    return new Promise((resolve) => {
      readProfile(profileId)
        .then((profile) => (profile ? mapProfileDetails(profile) : resolve(null)))
        .then((parents) => {
          resolve(parents);
        });
    });

    function readProfile(profileId) {
      return new Promise((resolve) => {
        ProfileStorage.Read(profileId, (profile) => {
          resolve(profile);
        });
      });
    }

    function mapProfileDetails(profile) {
      return new Promise((resolve) => {
        ProfileStorage.ReadProfileImage(profile.id, (pic) => {
          profile.pic = pic || ProfileDefault;
          resolve(profile);
        });
      });
    }
  }

  static inviteToTrustedPool(
    relationshipType,
    inviterId,
    inviterName,
    inviterPhone,
    inviteeName,
    inviteeMobileNumber,
    inviteeEmailId,
    callback,
    onNotify = (newNotification, inviteData) => {},
  ) {
    if (inviteeName !== null && inviteeMobileNumber !== null) {
      // Check if the friend is already on Ujama
      let profileIndex = -1;
      ProfileStorage.ReadList((profileList) => {
        if (inviteeMobileNumber === '') {
          profileIndex = -1;
        } else {
          profileIndex = profileList.findIndex((profile) => {
            return (
              profile.phone &&
              unformatPhoneNumber(profile.phone) === unformatPhoneNumber(inviteeMobileNumber)
            );
          });
        }
        const inviteeId = profileIndex !== -1 ? profileList[profileIndex].id : 0; //NEEDS TO BE CHANGED BACK

        const dateObj = new Date();
        const date = dateObj.toLocaleDateString();
        const time = dateObj.toLocaleTimeString();
        const inviteData = {};
        let key = '';
        if (inviteeMobileNumber === '') {
          key = encodeEmailForFirebaseKey(inviteeEmailId);
        } else {
          key = inviteeMobileNumber;
        }
        inviteData[key] = {
          inviterId: inviterId,
          inviteeId: inviteeId,
          inviteeName: inviteeName,
          relationshipType: relationshipType,
          inviteeMobileNumber: inviteeMobileNumber,
          inviteeEmailId: inviteeEmailId,
          status: 'pending',
          date: date,
          time: time,
        };

        const trustedString = relationshipType === 'family' ? 'family member' : 'trusted friend';
        const notificationMessage =
          inviterName + ' has invited you to be a ' + trustedString + ' in Ujama. Do you accept?';
        const newNotification = {
          senderId: inviterId,
          inviterId: inviterId,
          inviteeId: inviteeId,
          inviterName: inviterName,
          inviterPhone: inviterPhone,
          displayText: notificationMessage,
          date: date,
          time: time,
          inviteeName: inviteeName,
          relationshipType: relationshipType,
          inviteeMobileNumber: inviteeMobileNumber,
          type: 'invite',
          responseNeeded: true,
          status: 'new',
        };
        if (profileIndex !== -1) {
          // Invitee is already on Ujama - use our notifications to let them know
          console.log('already in Ujama, send notification');
          if (onNotify) {
            onNotify(newNotification, inviteData);
          }
        } else if (inviteeEmailId !== '') {
          const inviteeFirstName = inviteeName.split(' ')[0];
          const inviterFirstName = inviterName.split(' ')[0];
          const subject = 'Join ' + inviterName + ' on Ujama';
          const message =
            'Dear ' +
            inviteeFirstName +
            ',\n\nI would like to make you a ' +
            trustedString +
            ' on Ujama so we can easily arrange carpools and playdates for our kids. Please sign up here: https://app.ujama.co/?invitation=yes&inviter=' +
            inviterId +
            '\n\nSincerely,\n' +
            inviterFirstName;
          const to = inviteeName + '<' + inviteeEmailId + '>';

          /*
          NotificationStorage.CreateNotification(key, newNotification);
          NotificationStorage.UpdateInvitation(inviterId, inviteData);
          ProfileHelper.sendEmail(to, subject, message, (res) => {
            console.log(res);
            if (callback) {
              callback();
            }
          });
          */
        }
      });
    }
  }

  static sendEmail(to, subject, content, callback) {
    const headers = {
      'MIME-Version': '1.0\r\n',
      'Content-Type': 'text/html; charset=UTF-8',
      'X-UA-Compatible': 'IE=edge',
      viewport: 'width=device-width, initial-scale=1.0 ',
    };

    return fetch(ROUTES.SEND_EMAIL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        to: to,
        subject: subject,
        plaintext: content,
        headers: headers,
      }),
    })
      .then((response) => {
        callback(response.text());
      })
      .catch((error) => {
        console.error(error);
        callback(error);
      });
  }

  static getParentProfile(profileId) {
    return new Promise((resolve) => {
      readProfile(profileId)
        .then((profile) => this.mapParentProfile(profile))
        .then((parents) => {
          resolve(parents);
        });
    });

    function readProfile(profileId) {
      return new Promise((resolve) => {
        ProfileStorage.Read(profileId, (profile) => {
          resolve(profile);
        });
      });
    }
  }

  static mapParentProfile(profile) {
    return new Promise((resolve) => {
      if (!profile) {
        resolve(profile);
      } else {
        ProfileStorage.ReadProfileImage(profile.id, (parentPic) => {
          AuthHelper.getProfileDetails().then((userProfile) => {
            const userId = userProfile.id;
            const relationshipType = ProfileStorage.GetRelationshipType(profile, userId);

            CalculationsHelper.calculateTravelTime(
              userProfile.homeAddress,
              profile.homeAddress,
            ).then((travelTime) => {
              profile.pic = parentPic || ProfileDefault;
              profile.relationshipType = relationshipType;
              profile.travelTime = travelTime || null;

              resolve(profile);
            });
          });
        });
      }
    });
  }

  static getTrustedPool(profileId) {
    return new Promise((resolve, reject) => {
      readProfile(profileId)
        .then((profile) => getTrustedPoolList(profile))
        .then((trustedPool) => mapTrustedPoolProfiles(trustedPool))
        .then((parents) => {
          resolve(parents);
        })
        .catch((e) => reject(e));
    });

    function readProfile(profileId) {
      return new Promise((resolve) => {
        ProfileStorage.Read(profileId, (profile) => {
          resolve(profile);
        });
      });
    }

    function getTrustedPoolList(profile) {
      return new Promise((resolve) => {
        if (!profile) {
          resolve(null);
        } else {
          ProfileStorage.ReadAllTrustedPoolProfiles(profile, (trustedPool) => {
            ProfileStorage.GetPendingTrustedFriends(profile.id, (pendingFriends) => {
              if (pendingFriends && pendingFriends.length) {
                for (let i = 0; i < pendingFriends.length; i++) {
                  pendingFriends[i].isPending = true;
                  trustedPool.push(pendingFriends[i]);

                  if (i === pendingFriends.length - 1) {
                    resolve(trustedPool);
                  }
                }
              } else {
                resolve(trustedPool);
              }
            });
          });
        }
      });
    }

    function mapTrustedPoolProfiles(trustedPool) {
      return new Promise((resolve) => {
        let parentCount = 0;
        let parents = [];

        if (trustedPool && trustedPool.length) {
          trustedPool.forEach((parent) => {
            ProfileStorage.ReadProfileImage(parent.id, (pic) => {
              parent.pic = pic || ProfileDefault;
              parents.push(parent);

              if (++parentCount === trustedPool.length) {
                resolve(
                  parents.sort((a, b) => {
                    return a.name.toLowerCase() === b.name.toLowerCase()
                      ? 0
                      : a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : 1;
                  }),
                );
              }
            });
          });
        } else {
          resolve(null);
        }
      });
    }
  }

  static addToTrustedPool(friendId, relationshipType) {
    return new Promise((resolve) => {
      AuthHelper.getProfileDetails()
        .then((userProfile) =>
          ProfileStorage.AddTrustedFriend(userProfile.id, friendId, relationshipType),
        )
        .then((response) => {
          resolve(response);
        });
    });
  }

  static removeFromTrustedPool(friendId, friendPhone) {
    return new Promise((resolve) => {
      AuthHelper.getProfileDetails().then((userProfile) =>
        ProfileStorage.RemoveTrustedFriend(userProfile.id, friendId, friendPhone, (response) => {
          resolve(response);
        }),
      );
    });
  }

  static getKidsSchools(profileId) {
    return new Promise((resolve) => {
      readKids(profileId)
        .then((kids) => readKidsSchools(kids))
        .then((schools) => mapKidsSchools(schools))
        .then((kidsSchools) => {
          resolve(kidsSchools);
        });
    });

    function readKids(profileId) {
      return new Promise((resolve) => {
        KidStorage.ReadAllKids(profileId, (kids) => {
          resolve(kids);
        });
      });
    }

    function readKidsSchools(kids) {
      return new Promise((resolve) => {
        let schools = [];

        if (kids && kids.length) {
          let promises = [];

          for (let i = 0; i < kids.length; i++) {
            if (kids[i].schoolRef) {
              promises.push(
                checkIfSchoolExists(kids[i]).then((school) => {
                  if (school) {
                    schools.push(school);
                  }
                }),
              );
            }
          }
          Promise.all(promises).then(() => resolve(schools));
        } else {
          resolve(schools);
        }
      });

      function checkIfSchoolExists(kid) {
        return new Promise((resolve) => {
          SchoolStorage.Read(kid.schoolRef, (school) => {
            let verified = false;
            if (school) {
              if (school.verifiedList) {
                if (school.verifiedList[profileId]) {
                  verified = true;
                }
              }
              school.verified = verified;
            }
            resolve(school);
          });
        });
      }
    }

    function mapKidsSchools(schools) {
      return new Promise((resolve) => {
        let kidsSchools = [];
        let schoolsCount = 0;

        if (schools && schools.length) {
          schools.forEach((school) => {
            kidsSchools.push({
              id: school.id,
              name: school.name,
              verified: school.verified,
            });

            if (++schoolsCount === schools.length) {
              resolve(_.sortBy(_.uniqBy(kidsSchools, 'id'), 'name'));
            }
          });
        } else {
          resolve(kidsSchools);
        }
      });
    }
  }

  static getKidsActivities(profileId) {
    return new Promise((resolve) => {
      readKids(profileId)
        .then((kids) => readKidsActivities(kids))
        .then((activities) => mapKidsActivities(activities))
        .then((kidsActivities) => {
          resolve(kidsActivities);
        });
    });

    function readKids(profileId) {
      return new Promise((resolve) => {
        KidStorage.ReadAllKids(profileId, (kids) => {
          resolve(kids);
        });
      });
    }

    function readKidsActivities(kids) {
      return new Promise((resolve) => {
        let activities = [];
        let promises = [];

        if (kids && kids.length) {
          for (let i = 0; i < kids.length; i++) {
            if (kids[i].activity) {
              Object.values(kids[i].activity).forEach((activityData) => {
                promises.push(
                  new Promise((resolve) => {
                    OrganizationStorage.IsParentVerified(
                      activityData.orgId,
                      profileId,
                      (verified) => {
                        activityData.verified = verified;
                        activities.push(activityData);
                        resolve();
                      },
                    );
                  }),
                );
              });
            }

            if (i === kids.length - 1) {
              Promise.all(promises).then(() => {
                resolve(activities);
              });
            }
          }
        } else {
          Promise.all(promises).then(() => {
            resolve(activities);
          });
        }
      });
    }

    function mapKidsActivities(activities) {
      return new Promise((resolve) => {
        let kidsActivities = [];
        let activitiesCount = 0;

        if (activities && activities.length) {
          activities.forEach((activity) => {
            if (activity.orgId) {
              kidsActivities.push({
                classId: activity.classId,
                orgId: activity.orgId,
                name: activity.name,
                verified: activity.verified,
              });
            }

            if (++activitiesCount === activities.length) {
              resolve(_.sortBy(_.uniqBy(kidsActivities, 'classId'), 'name'));
            }
          });
        } else {
          resolve(kidsActivities);
        }
      });
    }
  }

  static getPartners(profileId) {
    return new Promise((resolve) => {
      AuthHelper.getProfileDetails().then((profile) => {
        const partners = [];

        if (!profile.partnerList) {
          resolve(partners);
        } else {
          const promises = [];

          for (let key in profile.partnerList) {
            promises.push(
              new Promise((resolve) => {
                PartnerStorage.Read(key, (partner) => {
                  if (partner) {
                    partners.push(partner);
                  }
                  resolve();
                });
              }),
            );
          }

          Promise.all(promises).then(() => {
            resolve(partners);
          });
        }
      });
    });
  }

  static addProfileDetails(profileId, profile, callback) {
    ProfileStorage.Add(profileId, profile).then(() => {
      if (callback) {
        callback();
      }
    });

    if (profile.file) {
      ProfileStorage.StoreProfileImageFromFile(profileId, profile.file);
    }
  }

  static updateProfileDetails(profileId, profile) {
    return new Promise((resolve, reject) => {
      ProfileStorage.Update(profileId, profile)
        .then(() => {
          if (profile.file) {
            ProfileStorage.StoreProfileImageFromFile(profileId, profile.file, (imageSource) => {
              ProfileHelper.refreshLocalStorage(profileId).then((profile) => resolve(profile));
            });
          } else {
            ProfileHelper.refreshLocalStorage(profileId).then((profile) => resolve(profile));
          }
        })
        .catch((error) => reject(error));
    });
  }

  static refreshLocalStorage(profileId) {
    return new Promise((resolve, reject) => {
      ProfileHelper.getProfileDetails(profileId).then((profile) => {
        try {
          localStorage.setItem('profile', JSON.stringify(profile));
          resolve(profile);
        } catch (error) {
          console.warn('Failed to update profile details in local storage!');
          reject(error);
        }
      });
    });
  }

  static GetFirebaseIdToken(callback) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        user.getIdToken().then((idToken) => {
          if (callback) return callback(idToken);
        });
      } else {
        // No user is signed in.
        console.log('user not signed in');
        if (callback) return callback(null);
      }
    });
  }

  static TriggerNewSignup(profile) {
    console.log('TriggerNewSignup ------------->', profile);
    return new Promise((resolve, reject) => {
      const payload = { profile: profile };
      ProfileHelper.GetFirebaseIdToken((idToken) => {
        fetch(`${ROUTES.URL_TRIGGER_SIGNUP}`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken,
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
          }),
          body: JSON.stringify({ profile: profile }),
        })
          .then((res) => {
            if (res.status !== 200) {
              return null;
            }
            return res.json();
          })
          .then((jsonRes) => resolve(jsonRes))
          .catch((err) => {
            reject(new Error('Error sending post for deactivated Account'));
            console.log('fetch failed! ', err);
          });
      });
    });
  }
}

export function filterVisibility(parentsList, listVisibilityLevel) {
  let filteredProfiles = [];

  parentsList.forEach((profile) => {
    if (typeof profile.visibility !== 'undefined') {
      if (profile.visibility >= listVisibilityLevel) {
        filteredProfiles.push(profile);
      }
    } else {
      filteredProfiles.push(profile);
    }
  });
  return filteredProfiles;
}

export default withRouter(ProfileHelper);
