import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';

import TabPanel from '../tab-panel';
import { ReactComponent as Home } from '../../../assets/icons/home.svg';
import { ReactComponent as Marketplace } from '../../../assets/icons/marketplace.svg';
import { ReactComponent as Messages } from '../../../assets/icons/messages.svg';
import { ReactComponent as Markets } from '../../../assets/icons/markets.svg';
import { ReactComponent as Vendors } from '../../../assets/icons/vendors.svg';
import { ReactComponent as Favorites } from '../../../assets/icons/favorites.svg';
import SendBirdUser from '../../../api/sendbird/sendbird-user';
import AuthHelper from '../../../api/helpers/auth';
import { IS_SOKO_APP } from '../../../utils/consts';

const TabIcon = (props) => {
  const { icon: Icon, active } = props;
  return <Icon className={`tabicon-${!active ? 'inactive' : 'active'}`} />;
};

const NavBarTabs = withRouter((props) => {
  const { isMobile = false } = props;
  let path = props.location?.pathname;

  // default to home
  path = !path || !path.length ? '/' : path;

  // remove nested paths for tab highlight
  path = `/${path.split('/')[1]}`;

  // disable when on other routes
  if (!['/', '/marketplace', '/messages', '/markets', '/vendors', '/favorites'].includes(path)) {
    path = false;
  }

  const [current, setCurrent] = useState(path || '/');
  const [unreadMessages, setUnreadMessages] = useState(0);
  function setCurrentTab(tab) {
    setCurrent(tab);
    props.onTabChanged(tab);
    props.history.push(tab);
    window.dispatchEvent(new CustomEvent('resize'));
  }

  useEffect(() => {
    AuthHelper.getProfileDetails().then((profile) => {
      if (profile) {
        SendBirdUser.Connect(profile, (sendBirdUser) => {
          SendBirdUser.GetChannelsWithUnreadMessages(profile.id, (channelList) => {
            setUnreadMessages(channelList.length);
          });
        });
      }
    });
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('resize')); // needed for MUI active tab indicator to work properly
    }, 250);
  }, []);

  // check if route has changed since previous render
  if (path !== current) {
    setCurrent(path);
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('resize')); // needed for MUI active tab indicator to work properly
    }, 250);
  }

  if (IS_SOKO_APP) {
    return (
      <>
        <MuiTabs
          value={current}
          indicatorColor="primary"
          style={{
            overflow: 'visible',
            width: '100%',
            height: 64,
          }}
        >
          <MuiTab
            style={isMobile ? styles.tabMobile : styles.tab}
            title="Markets"
            icon={<TabIcon icon={Markets} active={current === '/'} />}
            value="/"
            onClick={() => {
              setCurrentTab('/');
            }}
          />
          <MuiTab
            style={isMobile ? styles.tabMobile : styles.tab}
            title="Vendors"
            icon={<TabIcon icon={Vendors} active={current === '/vendors'} />}
            value="/vendors"
            onClick={() => {
              setCurrentTab('/vendors');
            }}
          />
          <MuiTab
            style={isMobile ? styles.tabMobile : styles.tab}
            title="Favorites"
            icon={<TabIcon icon={Favorites} active={current === '/favorites'} />}
            value="/favorites"
            onClick={() => {
              setCurrentTab('/favorites');
            }}
          />
          <MuiTab
            style={isMobile ? styles.tabMobile : styles.tab}
            title="Messages"
            className={unreadMessages > 0 ? 'tab-unread' : ''}
            icon={<TabIcon icon={Messages} active={current === '/messages'} />}
            value="/messages"
            onClick={() => {
              setCurrentTab('/messages');
            }}
          />
        </MuiTabs>

        <TabPanel current={current} index="/"></TabPanel>
        <TabPanel current={current} index="/marketplace"></TabPanel>
        <TabPanel current={current} index="/favorites"></TabPanel>
        <TabPanel current={current} index="/messages"></TabPanel>
      </>
    );
  } else {
    return (
      <>
        <MuiTabs
          value={current}
          indicatorColor="primary"
          style={{
            overflow: 'visible',
            width: '100%',
            height: 64,
          }}
        >
          <MuiTab
            style={isMobile ? styles.tabMobile : styles.tab}
            title="Home"
            icon={<TabIcon icon={Home} active={current === '/'} />}
            value="/"
            onClick={() => {
              setCurrentTab('/');
            }}
          />
          <MuiTab
            style={isMobile ? styles.tabMobile : styles.tab}
            title="Marketplace"
            icon={<TabIcon icon={Marketplace} active={current === '/marketplace'} />}
            value="/marketplace"
            onClick={() => {
              setCurrentTab('/marketplace');
            }}
          />
          <MuiTab
            style={isMobile ? styles.tabMobile : styles.tab}
            title="Messages"
            className={unreadMessages > 0 ? 'tab-unread' : ''}
            icon={<TabIcon icon={Messages} active={current === '/messages'} />}
            value="/messages"
            onClick={() => {
              setCurrentTab('/messages');
            }}
          />
        </MuiTabs>

        <TabPanel current={current} index="/"></TabPanel>
        <TabPanel current={current} index="/marketplace"></TabPanel>
        <TabPanel current={current} index="/messages"></TabPanel>
      </>
    );
  }
});

const styles = {
  tab: {
    minWidth: 120,
    textAlign: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  tabMobile: {
    width: '33%',
  },
};

export default NavBarTabs;
