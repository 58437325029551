import moment from 'moment-timezone';
import { RECURRENCES, REGULAR_FREQUENCIES } from '../../utils/consts';

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const isIterable = (object) => object != null && typeof object[Symbol.iterator] === 'function';

export function markIfClosedForTheSeason(market) {
  if (market.startDate && market.endDate) {
    const today = new Date();
    const thisYear = today.getFullYear();
    const start = new Date(market.startDate);
    const end = new Date(market.endDate);

    // Since our market data may have stale data, let's assume we can use
    // previous season's start and end dates for this season
    const endYear = end.getFullYear();
    if (endYear < thisYear) {
      end.setFullYear(thisYear);

      const startYear = start.getFullYear();
      if (startYear < thisYear) {
        // Bump up the start year by the same amount we bumped up the end year
        // (Since winter markets may start in one year and end the next year)
        start.setFullYear(startYear + thisYear - endYear);
        market.hasInaccurateStartDate = true;
      }
    }

    if (today < start) {
      market.closed = true;
      market.reopens = start;
    } else if (today > end) {
      market.closed = true;
      start.setFullYear(start.getFullYear() + 1);
      market.hasInaccurateStartDate = true;
      market.reopens = start;
    } else {
      market.closed = false;
      market.end = end;
    }
  } else {
    market.closed = false;
    market.end = null;
  }
}

export function getOpenDays(market, monthInterval = 3) {
  const { REGULAR, IRREGULAR, SPECIFIC_DAYS } = RECURRENCES;
  const { recurrence_type } = market.timings;
  let days = [];

  if (recurrence_type) {
    switch (recurrence_type) {
      case REGULAR:
        days = getRegularOpenDays(market, monthInterval);
        break;
      case IRREGULAR:
        days = getIrregularOpenDays(market, monthInterval);
        break;
      case SPECIFIC_DAYS:
        days = getSpecificDatesOpenDays(market, monthInterval);
        break;

      default:
      // Error case
    }

    days = filterOutHolidays(days, market);
  } else {
    days = getNoRecurrenceTypeOpenDays(market, monthInterval);
  }

  days.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    return dateA - dateB;
  });

  return days;
}

function getNoRecurrenceTypeOpenDays(market, monthInterval) {
  let openDays = [];
  let today = new Date();
  let iterations = monthInterval * 4; // weekly

  market.timings.forEach(({ day, startTime, endTime }) => {
    let indexDay = DAYS.indexOf(day);
    for (let i = 0; i < iterations; i++) {
      const chosenDate = new Date();
      const fromDate = moment(startTime, 'LT').toDate();
      const toDate = moment(endTime, 'LT').toDate();

      chosenDate.setDate(chosenDate.getDate() + ((indexDay + 7 - chosenDate.getDay()) % 7 || 7));
      chosenDate.setDate(chosenDate.getDate() + 7 * i);

      if (
        chosenDate.getDate() === today.getDate() &&
        chosenDate.getMonth() === today.getMonth() &&
        chosenDate.getFullYear() === today.getFullYear()
      ) {
        toDate.setDate(chosenDate.getDate());
        toDate.setMonth(chosenDate.getMonth());
        toDate.setFullYear(chosenDate.getFullYear());
        if (today < toDate) {
          openDays.push({
            date: chosenDate,
            from: fromDate,
            to: toDate,
          });
        }
      } else {
        openDays.push({
          date: chosenDate,
          from: fromDate,
          to: toDate,
        });
      }
    }
  });

  return openDays;
}

function getRegularOpenDays(market, monthInterval) {
  const { frequency, days, start_from } = market.timings;
  const iterations = {
    every_week: monthInterval * 4,
    every_two_weeks: monthInterval * 2,
    every_four_weeks: monthInterval,
  };
  const weekMultiplier = {
    every_week: 1,
    every_two_weeks: 2,
    every_four_weeks: 4,
  };
  const openDays = [];
  const today = moment();
  const finalIteration = iterations[frequency];

  days.forEach(({ day, from, to }) => {
    const fromDate = moment(from);
    const toDate = moment(to);
    const anchorDate = getUpcomingWeekday(day);
    const differenceInWeeks = start_from ? anchorDate.diff(moment(start_from), 'weeks') : 0;

    if (differenceInWeeks) {
      anchorDate.add(differenceInWeeks % weekMultiplier[frequency], 'weeks');
    }

    for (let i = 0; i < finalIteration; i++) {
      const chosenDate = moment(anchorDate);

      chosenDate.add(7 * i * weekMultiplier[frequency], 'days');

      if (
        chosenDate.get('date') === today.get('date') &&
        chosenDate.get('month') === today.get('month') &&
        chosenDate.get('year') === today.get('year')
      ) {
        toDate.set('date', chosenDate.get('date'));
        toDate.set('month', chosenDate.get('month'));
        toDate.set('year', chosenDate.get('year'));
        if (today < toDate) {
          openDays.push({
            date: chosenDate,
            from: fromDate,
            to: toDate,
          });
        }
      } else {
        openDays.push({
          date: chosenDate,
          from: fromDate,
          to: toDate,
        });
      }
    }
  });

  return openDays;
}

function getUpcomingWeekday(day) {
  const indexDay = DAYS.indexOf(day);
  const todayIndex = moment().isoWeekday();

  // if we haven't yet passed the day of the week that I need:
  if (todayIndex <= indexDay) {
    // then just give me this week's instance of that day
    return moment().isoWeekday(indexDay);
  } else {
    // otherwise, give me *next week's* instance of that same day
    return moment().add(1, 'weeks').isoWeekday(indexDay);
  }
}

function getSpecificDatesOpenDays(market, monthInterval) {
  const { specific_days } = market.timings;
  const today = new Date();

  return specific_days
    .filter((day) => {
      const specificDate = new Date(day.date);

      if (
        specificDate.getDate() === today.getDate() &&
        specificDate.getMonth() === today.getMonth() &&
        specificDate.getFullYear() === today.getFullYear()
      ) {
        const toDate = new Date(day.endTime);
        toDate.setDate(specificDate.getDate());
        toDate.setMonth(specificDate.getMonth());
        toDate.setFullYear(specificDate.getFullYear());
        return today < toDate;
      } else {
        return specificDate > today;
      }
    })
    .map((day) => {
      return {
        date: moment(day.date),
        from: moment(day.startTime),
        to: moment(day.endTime),
      };
    });
}

function getIrregularOpenDays(market, monthInterval) {
  const { date, frequency, time } = market.timings.irregular;
  const days = [];
  const day = DAYS.indexOf(date);
  const today = new Date();
  const thisMonth = today.getMonth();

  for (let i = 0; i < monthInterval; i++) {
    const month = (thisMonth + i) % 12;
    const openDays = getIrregularMoment(day, month, moment(time.from), moment(time.to), frequency);

    days.push(...openDays);
  }

  return days;
}

function getIrregularMoment(day, month, from, to, frequency) {
  const firstDayOfMonth = moment()
    .set('month', month)
    .startOf('month')
    .add(6 - moment().day(day).day(), 'days')
    .startOf('week')
    .day(day);

  const lastDayOfMonth = moment().set('month', month).endOf('month').day(day);

  if (lastDayOfMonth.get('month') !== month) {
    lastDayOfMonth.subtract(1, 'week');
  }

  const irregularFrequency = {
    First: () => [{ date: firstDayOfMonth, from, to }],
    Second: () => [{ date: firstDayOfMonth.add(1, 'week'), from, to }],
    Third: () => [{ date: firstDayOfMonth.add(2, 'week'), from, to }],
    Fourth: () => [{ date: firstDayOfMonth.add(3, 'week'), from, to }],
    Last: () => [{ date: lastDayOfMonth, from, to }],
    'First and Second': () => [
      { date: firstDayOfMonth.toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
    ],
    'First and Third': () => [
      { date: firstDayOfMonth.toDate(), from, to },
      { date: firstDayOfMonth.add(2, 'week').toDate(), from, to },
    ],
    'First and Fourth': () => [
      { date: firstDayOfMonth.toDate(), from, to },
      { date: firstDayOfMonth.add(3, 'week').toDate(), from, to },
    ],
    'First, Second and Third': () => [
      { date: firstDayOfMonth.toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
    ],
    'First, Second and Fourth': () => [
      { date: firstDayOfMonth.toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
      { date: firstDayOfMonth.add(2, 'week').toDate(), from, to },
    ],
    'First, Third and Fourth': () => [
      { date: firstDayOfMonth.toDate(), from, to },
      { date: firstDayOfMonth.add(2, 'week').toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
    ],
    'Second and Third': () => [
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
    ],
    'Second and Fourth': () => [
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
      { date: firstDayOfMonth.add(2, 'week').toDate(), from, to },
    ],
    'Second, Third, and Fourth': () => [
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
    ],
    'Third and Fourth': () => [
      { date: firstDayOfMonth.add(2, 'week').toDate(), from, to },
      { date: firstDayOfMonth.add(1, 'week').toDate(), from, to },
    ],
  };

  return irregularFrequency[frequency]().filter((day) => {
    let today = new Date();
    day = new Date(day.date);
    if (
      day.getDate() === today.getDate() &&
      day.getMonth() === today.getMonth() &&
      day.getFullYear() === today.getFullYear()
    ) {
      const toDate = new Date(to);
      toDate.setDate(day.getDate());
      toDate.setMonth(day.getMonth());
      toDate.setFullYear(day.getFullYear());
      return today < toDate;
    }
    return day > today;
  });
}

function filterOutHolidays(openDays, market) {
  const { holidays } = market.timings;
  let filteredOpenDays = openDays;

  if (holidays) {
    filteredOpenDays = filteredOpenDays.filter(
      (openDay) =>
        !holidays.find((holiday) => {
          let holidayDate = new Date(holiday.date);
          let openDayDate = new Date(openDay.date);

          return (
            holidayDate.getDate() === openDayDate.getDate() &&
            holidayDate.getMonth() === openDayDate.getMonth() &&
            holidayDate.getFullYear() === openDayDate.getFullYear()
          );
        }),
    );
  }

  return filteredOpenDays;
}

export function getNextOpenDay(market) {
  let nextOpenDay = null;

  if (!market.closedUntilFurtherNotice && !market.closed) {
    const openDays = getOpenDays(market);

    if (openDays && openDays.length) {
      nextOpenDay = openDays[0];
    }
  }

  return nextOpenDay;
}

export function getMarketTimingsString(market) {
  let ret = '';

  if (isIterable(market.timings)) {
    ret = getOldMarketTimingsString(market);
  } else if (market.timings && market.timings.recurrence_type) {
    const { recurrence_type } = market.timings;
    const nextOpenDay = getNextOpenDay(market);

    if (recurrence_type === RECURRENCES.SPECIFIC_DAYS) {
      ret = getSelectedDatesMarketTimingsString(market, nextOpenDay);
    } else {
      ret = market.timings?.detail;

      if (
        (market.timings.recurrence_type !== RECURRENCES.REGULAR ||
          market.timings.frequency !== REGULAR_FREQUENCIES.EVERY_WEEK) &&
        nextOpenDay
      ) {
        ret += `\n\nNext market day on ${moment(nextOpenDay.date).format('dddd MMMM Do, YYYY')}`;
      }

      if (!market.timings.detail) {
        ret = `Error reading timings from market #${market.id}`;
      }
    }
  } else {
    ret = `Error reading timings from market #${market.id}`;
  }

  return ret;
}

function getOldMarketTimingsString(market) {
  let ret = '';

  market.timings.forEach((timing, index) => {
    if (ret) {
      if (index === market.timings.length - 1) {
        ret += ' and ';
      } else {
        ret += ', ';
      }
    }
    ret += `${timing.day} from ${timing.startTime} to ${timing.endTime}`;
  });

  return ret;
}

function getSelectedDatesMarketTimingsString(market, nextOpenDay = null) {
  const { specific_days } = market.timings;
  let ret = '';

  if (specific_days) {
    const openDays = getOpenDays(market, 2);
    ret = 'Upcoming Market Days:';

    if (openDays.length) {
      openDays.forEach((day) => {
        ret += `\n∙ ${moment(day.date).format('dddd MMMM Do, YYYY')} from ${moment(day.from).format(
          'LT',
        )} to ${moment(day.to).format('LT')}`;
      });
    } else {
      ret += '\n(None in our schedule)';
    }
  }

  return ret;
}
