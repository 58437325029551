import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Workbox } from 'workbox-window';
import { CssBaseline as CssReset, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider as PickersUtils } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/min/locales';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import history from './history';
import App from './app';
import theme from './theme';
const locale = window.navigator.userLanguage || window.navigator.language || 'en';
moment.locale(locale);

const renderApp = () => {
  return (
    <StrictMode>
      <MuiThemeProvider theme={theme}>
        <PickersUtils libInstance={moment} utils={MomentUtils} locale={locale}>
          <CssReset />
          <ToastContainer
            toastClassName="toast-message"
            position="bottom-left"
            closeButton={false}
            closeOnClick={true}
            hideProgressBar={true}
          />
          <Router history={history}>
            <App />
          </Router>
        </PickersUtils>
      </MuiThemeProvider>
    </StrictMode>
  );
};

ReactDOM.render(renderApp(), document.getElementById('ujama'));

// register the service worker
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/service-worker.js');
  window.promptedRefresh = false;

  wb.addEventListener('activated', (event) => {
    console.info('SW activated', event);
  });

  wb.addEventListener('waiting', (event) => {
    console.info('SW waiting', event);
    if (window.confirm(`New content is available!. Click OK to refresh.`)) {
      wb.addEventListener('controlling', (event) => {
        window.location.reload();
      });
      wb.messageSkipWaiting();
    }

    console.warn(
      `A new service worker has installed, but it can't activate` +
        `until all tabs running the current version have fully unloaded.`,
    );
  });

  wb.addEventListener('installed', (event) => {
    console.info('SW installed', event);
    if (event.isUpdate) {
      console.warn('Update available');
    }
  });

  wb.addEventListener('message', (event) => {
    console.log('message', event);
    if (event.data.type === 'CACHE_UPDATED' && !window.promptedRefresh) {
      const { updatedURL } = event.data.payload;
      console.log('Cache updated', updatedURL);
    }
  });

  wb.register();
}
