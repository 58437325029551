import FirebaseManager from '../firebase/firebase-manager';

export default class MarketStorage {
  static Read(marketID, callback) {
    const firebaseManager = new FirebaseManager();

    firebaseManager.readOnce('markets/' + marketID, (snap) => {
      const market = snap && snap.exists() ? snap.val() : null;
      if (market) {
        market.id = snap.key;
      }
      callback(market);
    });
  }

  static Add(market) {
    const firebaseManager = new FirebaseManager();
    const today = new Date();

    market.created = today.toISOString();
    market.createdBy = ''; // TODO - get current user

    return firebaseManager.saveData('markets/', market);
  }

  static Update(market, marketID) {
    const today = new Date();
    const firebaseManager = new FirebaseManager();

    market.lastEdit = today.toISOString();
    market.lastEditBy = ''; // TODO - get current user

    return firebaseManager.update('markets/' + marketID, market);
  }

  static Delete(marketID) {
    const firebaseManager = new FirebaseManager();
    firebaseManager.deleteValue('markets/' + marketID);
  }

  static ReadAll(callback) {
    const firebaseManager = new FirebaseManager();
    const marketList = [];

    firebaseManager.readOnce('markets', (snap) => {
      snap.forEach((child) => {
        const market = child.val();
        if (market) {
          market.id = child.key;
          marketList.push(market);
        }
      });
      callback(marketList);
    });
  }

  static AddVendor(marketID, profileID) {
    return new Promise((resolve, reject) => {
      MarketStorage.Read(marketID, (market) => {
        if (market) {
          const today = new Date();
          const firebaseManager = new FirebaseManager();

          if (!market.vendors) {
            market.vendors = {};
          }
          market.vendors[profileID] = today.toISOString();

          // Remove from pending status
          if (market.vendorsPending) {
            market.vendorsPending[profileID] = null;
          }

          return firebaseManager.update('markets/' + marketID, market).then(() => resolve(market));
        } else {
          reject(new Error('Invalid marketID'));
        }
      });
    });
  }

  static StoreImage(file) {
    return new Promise((resolve, reject) => {
      const firebaseManager = new FirebaseManager();
      const now = new Date();
      const id = `market-${now.toISOString()}`;

      try {
        firebaseManager.storeImageFromFile(`markets/${id}`, file, (imageSource) => {
          resolve(imageSource);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
