import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import AddressPage from './address-page';

export default class AddressModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      handler: null,
    };
  }

  launch(handler) {
    this.setState({
      modalVisible: true,
      handler,
    });
  }

  onChange = (address) => {
    this.state.handler(address);
    this.setState({ modalVisible: false });
  };

  render() {
    const { modalVisible } = this.state;

    return (
      <Dialog open={modalVisible}>
        <DialogTitle>
          <span style={{ fontSize: 24, fontWeight: '600' }}>{'Enter Address'}</span>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600 }}>
          <AddressPage location={{ state: {} }} embedded={true} onChange={this.onChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ modalVisible: false })} color="primary">
            {'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
