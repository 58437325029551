import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  CircularProgress,
  Card,
  CardActionArea,
  Dialog,
  Button,
  TextField,
} from '@material-ui/core';
import UjamaAppBar from '../../common/material-wrap/app-bar';
import { getPlaceDetails, parseAddressComponents } from '../../../api/helpers/geolocation-help';
import './address-page.css';

class AddressPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: '',
      isVisible: false,
      address2: '',

      showWorking: false,
    };

    this.addressComponents = {
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    };

    this.coords = null;
  }

  componentDidMount() {
    if (
      typeof this.props.location === 'undefined' ||
      typeof this.props.location.state === 'undefined'
    ) {
      this.props.history.goBack();
    }
    if (this.props.location.state.address) {
      this.setState({ address: this.props.location.state.address });
    }
  }

  render() {
    return (
      <div className="container">
        {this.renderModal()}
        {this.renderContent()}
      </div>
    );
  }

  onAddressConfirmed() {
    if (this.state.address2[0]) {
      this.addressComponents.address2 = this.state.address2;
      if (this.addressComponents.city[0]) {
        const position = this.fullAddress.indexOf(this.addressComponents.city);
        this.fullAddress = [
          this.fullAddress.slice(0, position),
          ' ' + this.state.address2 + ', ',
          this.fullAddress.slice(position),
        ].join('');
      }
    }
    this.handleBack();
  }

  preSelect(address, placeId) {
    this.setState({ showWorking: true });
    getPlaceDetails(placeId, (details) => {
      this.setState({ showWorking: false });

      this.coords = {
        longitude: details.geometry.location.lng,
        latitude: details.geometry.location.lat,
      };
      this.addressComponents = parseAddressComponents(details.address_components);
      if (
        !this.addressComponents.streetName &&
        !this.props.location &&
        !this.props.location.state &&
        !this.props.location.state.allowLooseAddress
      ) {
        window.alert('Please select an address with a street name');
      } else {
        if (!this.addressComponents.streetName) {
          this.addressComponents.streetName = details.formatted_address.split(',')[0];
        }
        if (details.types.indexOf('establishment') !== -1) {
          this.fullAddress = details.name + ', ' + details.formatted_address;
        } else {
          this.fullAddress = details.formatted_address;
        }
        this.setState({ isVisible: true });
      }
    });
  }

  handleBack = () => {
    const state = {
      updateLocation: {
        key: this.props.location?.state?.key || 'homeAddress',
        address: {
          fullAddress: this.fullAddress,
          addressComponents: this.addressComponents,
          coords: this.coords,
        },
      },
    };

    if (this.props.onChange) {
      this.props.onChange(state.updateLocation.address);
    } else {
      console.log('handleBack', state, this.props.location.state);
      if (this.props.location?.state?.returnPath) {
        const location = {
          pathname: this.props.location.state.returnPath,
          state,
        };
        this.props.history.replace(location);
      } else {
        this.props.history.goBack({ state });
      }
    }
  };

  renderContent() {
    return (
      <div>
        {!this.props.embedded && (
          <UjamaAppBar
            title={
              this.props.location.state && this.props.location.state.name
                ? this.props.location.state.name
                : 'Enter Address'
            }
            type="cancel"
          />
        )}

        <Card
          style={{
            textAlign: 'left',
            padding: this.props.embedded ? 0 : 30,
          }}
        >
          <PlacesAutocomplete
            value={this.state.address}
            styles={{ ...styles.input, ...styles.autocompleteContainer }}
            onChange={(address) => this.setState({ address })}
            onSelect={(address, placeId) => this.preSelect(address, placeId)}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="ujama-address-search-bar-container">
                <div className="ujama-address-search-input-container">
                  <input
                    {...getInputProps({
                      placeholder: 'Type an address...',
                    })}
                    className="ujama-address-search-input"
                  />
                  <div className="ujama-address-autocomplete-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? 'ujama-address-suggestion-item--active'
                        : 'ujama-address-suggestion-item';

                      return (
                        <div
                          key={`address_suggestion_${index}`}
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <span style={{ fontSize: 16, lineHeight: 2, marginLeft: 10 }}>
                            {suggestion.description}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Card>
        {this.renderWorking()}
        <div style={{ height: 20 }} />
      </div>
    );
  }

  renderModal() {
    const streetNumber =
      this.addressComponents.streetNumber && this.addressComponents.streetNumber[0]
        ? this.addressComponents.streetNumber + ' '
        : '';
    const city =
      this.addressComponents.city && this.addressComponents.city[0]
        ? this.addressComponents.city + ', '
        : '';

    return (
      <Dialog open={this.state.isVisible}>
        <Card style={{ textAlign: 'center', minWidth: 400 }}>
          <div style={{ flexDirection: 'row' }}>
            <div style={styles.modalHeader}>
              <span style={styles.modalHeaderText}>Confirm Address</span>
            </div>
          </div>

          <div
            style={{
              display: 'inline-block',
              textAlign: 'left',
              fontSize: 16,
              marginBottom: 20,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{streetNumber + this.addressComponents.streetName}</span>

              <TextField
                placeholder="Address 2 (optional)"
                onChange={(e) => this.setState({ address2: e.target.value })}
                value={this.state.address2}
                style={{ fontSize: 16, minWidth: 300 }}
              />

              <span style={{ marginTop: 10 }}>
                {(city || '') +
                  (this.addressComponents.state || '') +
                  ' ' +
                  (this.addressComponents.zip || '')}
              </span>
            </div>
          </div>

          <CardActionArea>
            <Button
              style={{
                backgroundColor: '#F26739',
                color: 'white',
                borderRadius: 4,
                margin: 10,
              }}
              onClick={() => this.onAddressConfirmed()}
            >
              OK, looks good!
            </Button>

            <Button
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: 4,
                margin: 10,
              }}
              onClick={() => this.setState({ isVisible: false })}
            >
              Cancel
            </Button>
          </CardActionArea>
          <div style={{ height: 10 }} />
        </Card>
      </Dialog>
    );
  }

  renderWorking() {
    if (this.state.showWorking) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      );
    }
  }
}

const styles = {
  button: {
    padding: 8,
    margin: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  buttonText: {
    color: 'white',
  },
  modalHeader: {
    textAlign: 'center',
    backgroundColor: '#8CE0EA',
    color: 'white',
    flex: 1,
    alignItems: 'center',
    borderColor: 'black',
    padding: 8,
    marginBottom: 20,
  },
  modalHeaderText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '600',
  },
  input: {
    padding: 4,
    fontSize: 14,
    fontWeight: '100',
    color: 'black',
    textAlign: 'left',
    borderWidth: 1,
    borderColor: '#D3D3D3',
  },
  autocompleteContainer: {
    minWidth: 250,
    width: '100%',
  },
};

export default AddressPage;
