import React from 'react';
import { toast } from 'react-toastify';
import {
  AppBar,
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { Edit, People, Store } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';
import ROUTES from '../../utils/consts';
import MarketStorage from '../../api/storage/markets';
import RolesStorage from '../../api/storage/roles';
import { getMarketTimingsString } from '../../api/helpers/market';

export default class SokoMarketList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markets: [],
      filteredResults: [],
      loading: true,
      searchAddress: '',
      searchCoords: {},
      searchText: '',
      showSearching: false,
      displayLimit: 50,
      adminStatus: '',
    };

    this.typingTimeout = null;
  }

  componentDidMount() {
    this.readData();

    RolesStorage.CheckSuperUser((adminStatus) => {
      this.setState({ adminStatus });
    });
  }

  readData() {
    MarketStorage.ReadAll((markets) => {
      this.checkIfClosed(markets);
      const sortedMarkets = markets.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });

      this.setState({
        markets: sortedMarkets,
        filteredResults: sortedMarkets,
        loading: false,
      });
    });
  }

  checkIfClosed(markets) {
    const today = new Date();
    const thisYear = today.getFullYear();

    markets.forEach((market) => {
      if (market.startDate && market.endDate) {
        const start = new Date(market.startDate);
        const end = new Date(market.endDate);

        // Since our market data may have stale data, let's assume we can use
        // previous season's start and end dates for this season
        if (end.getFullYear() < thisYear) {
          end.setFullYear(thisYear);
        }
        if (start.getFullYear() <= thisYear) {
          start.setFullYear(thisYear + 1);
          market.hasInaccurateDates = true;
        }

        if (today > end) {
          market.closed = true;
          market.reopens = start;
        } else {
          market.closed = false;
        }
      } else {
        market.closed = false;
      }
    });
  }

  filterMarkets = () => {
    const { searchText, markets } = this.state;

    if (!searchText) {
      this.setState({ filteredResults: markets, showSearching: false, displayLimit: 50 });
    } else {
      const filteredResults = markets.filter((market) => {
        const stringToSearch = `${market.name} ${market.location.address} ${market.location.city} ${market.location.state} ${market.location.zip}`;
        return stringToSearch.toLowerCase().includes(searchText.toLowerCase());
      });
      this.setState({ filteredResults, showSearching: false, displayLimit: 50 });
    }
  };

  delayFilterMarkets = () => {
    this.setState({ showSearching: true });
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    this.typingTimeout = setTimeout(() => {
      this.typingTimeout = null;
      this.filterMarkets();
    }, 1000);
  };

  onSearchChange = (event) => {
    this.setState({ searchText: event.target.value }, this.delayFilterMarkets);
  };

  onAdd = () => {
    const { adminStatus } = this.state;

    if (adminStatus === 'ujama' || adminStatus === 'employee') {
      let location = {
        pathname: `${ROUTES.EDIT_MARKET}`,
        state: {
          title: 'Add new market',
        },
      };
      this.props.history.push(location);
    } else {
      toast.warning(
        "Sorry! You don't have permission to add a market. If you need access, please contact support@ujama.co",
      );
    }
  };

  onViewMarket(market) {
    this.props.history.push({
      pathname: `/market-private/${market.id}`,
      state: { showToolbar: true },
    });
  }

  onEditMarket(market) {
    const { adminStatus } = this.state;

    if (adminStatus === 'ujama' || adminStatus === 'employee') {
      let location = {
        pathname: `${ROUTES.EDIT_MARKET}`,
        state: {
          market: market,
          title: market.name,
          timings: market.timings,
        },
      };
      this.props.history.push(location);
    } else {
      toast.warning(
        "Sorry! You don't have permission to edit a market. If you need access, please contact support@ujama.co",
      );
    }
  }

  onShowVendors(market) {
    let location = {
      pathname: '/vendors',
      state: {
        market,
      },
    };
    this.props.history.push(location);
  }

  onSeeFollowerList = (market) => {
    const { adminStatus } = this.state;

    if (adminStatus === 'ujama' || adminStatus === 'employee') {
      const location = {
        pathname: `${ROUTES.FIND_PARENTS}`,
        state: {
          title: `${market.name} Followers`,
          favoriteMarketId: market.id,
        },
      };
      this.props.history.push(location);
    } else {
      toast.warning(
        "Sorry! You don't have permission to see a market's follower list. If you need access, please contact support@ujama.co",
      );
    }
  };

  render() {
    return (
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Typography variant="h1">Markets</Typography>
            <section>
              <IconButton variant="contained" color="primary" onClick={this.onAdd}>
                Add...
              </IconButton>
            </section>
          </Toolbar>
        </AppBar>

        <div style={{ width: '100%', alignItems: 'top', textAlign: 'left' }}>
          <div ref={this.searchBox}>
            <TextField
              name={'searchText'}
              label="Search..."
              placeholder="Search for a market name, address or zip code"
              style={{ margin: 14, width: '50%' }}
              value={this.state.searchText}
              onChange={this.onSearchChange}
              InputProps={
                this.state.showSearching
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={20} />
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
            />
          </div>
          {this.renderResults()}
          <div style={{ height: 10 }} />
        </div>
      </div>
    );
  }

  renderResults() {
    const { filteredResults, displayLimit, loading } = this.state;

    if (loading) {
      return this.renderWaiting();
    } else {
      return (
        <section className="screen-holder">
          <List>{this.renderList()}</List>
          {filteredResults.length > displayLimit && (
            <Button onClick={() => this.setState({ displayLimit: displayLimit + 50 })}>
              Show More...
            </Button>
          )}
        </section>
      );
    }
  }

  renderList() {
    const { filteredResults, displayLimit, searchText } = this.state;
    const fragments = [];

    filteredResults.forEach((market, index) => {
      if (index < displayLimit) {
        fragments.push(
          <ListItem key={market.id} button divider onClick={() => this.onViewMarket(market)}>
            <ListItemAvatar>
              <Avatar alt={market.name} src={market.splash} />
            </ListItemAvatar>
            <ListItemText
              inset
              primary={
                <div style={{ width: '70%' }}>
                  <Highlighter
                    highlightStyle={styles.textHighlight}
                    searchWords={[searchText]}
                    textToHighlight={market.name}
                  />
                </div>
              }
              primaryTypographyProps={{ variant: 'h4', style: { whiteSpace: 'normal' } }}
              secondary={
                <div>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ whiteSpace: 'normal', width: '70%' }}
                  >
                    {getMarketTimingsString(market)}
                  </Typography>
                  {market.closed && !market.closedUntilFurtherNotice && (
                    <Typography variant="body2" color="secondary" style={{ whiteSpace: 'normal' }}>
                      {`The market is CLOSED for the season and will reopen ${
                        market.hasInaccurateDates
                          ? 'in ' + moment(market.reopens).format('MMMM YYYY')
                          : 'on ' + market.reopens.toLocaleDateString()
                      }`}
                    </Typography>
                  )}
                  {market.closedUntilFurtherNotice && (
                    <Typography variant="body2" color="secondary" style={{ whiteSpace: 'normal' }}>
                      The market is CLOSED until further notice.
                    </Typography>
                  )}
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{ whiteSpace: 'normal' }}
                  >
                    <Highlighter
                      highlightStyle={styles.textHighlight}
                      searchWords={[searchText]}
                      textToHighlight={`${market.location.address}, ${market.location.city} ${market.location.state} ${market.location.zip}`}
                    />
                  </Typography>
                </div>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title="Edit the market data">
                <Button onClick={() => this.onEditMarket(market)}>
                  <Edit />
                </Button>
              </Tooltip>
              <Tooltip title="Show the vendor directory">
                <Button onClick={() => this.onShowVendors(market)}>
                  <Store />
                </Button>
              </Tooltip>
              <Tooltip title="See this market's list of followers">
                <Button onClick={() => this.onSeeFollowerList(market)}>
                  <People />
                </Button>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>,
        );
      }
    });

    return fragments;
  }

  renderWaiting() {
    return (
      <section className="screen-holder" style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </section>
    );
  }
}

const styles = {
  textHighlight: {
    color: '#68CDDC',
    fontWeight: '800',
  },
};
