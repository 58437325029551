import React from 'react';
import logo from '../../assets/icons/ujama.svg';
import mail from '../../assets/icons/mail.svg';
import { IS_SOKO_APP } from '../../utils/consts';

export default function Footer(props) {
  return (
    <div
      style={{
        ...(props.isMobile ? styles.footerMobile : styles.footer),
        ...(props.fullWidth ? { width: '100vw' } : {}),
        ...(props.backgroundColor
          ? { backgroundColor: props.backgroundColor }
          : { backgroundColor: '#f2f2f2' }),
      }}
      id="footer"
    >
      <div style={styles.wrap}>
        <img src={logo} alt="Ujama logo" style={styles.icon} /> &copy; {new Date().getFullYear()}{' '}
        Ujama Inc.
        <br />
        <img src={mail} alt="Mail address" style={styles.icon} /> 19925 Stevens Creek Blvd, Suite
        100, Cupertino CA 95014
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={IS_SOKO_APP ? 'https://sokoapp.co/privacy.pdf' : 'https://ujama.co/privacy.pdf'}
        >
          Privacy
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={IS_SOKO_APP ? 'https://ujama.co/terms.pdf' : 'https://ujama.co/terms.pdf'}
        >
          Terms
        </a>
      </div>
    </div>
  );
}

const styles = {
  wrap: {
    maxWidth: '90%',
    margin: '0 5%',
    color: '#B1B1B1',
    lineHeight: '32px',
  },
  footer: {
    padding: '30px 0 50px',
  },
  footerMobile: {
    padding: '30px 14px 100px',
  },
  icon: {
    width: '20px',
    verticalAlign: 'middle',
    margin: 4,
  },
};
