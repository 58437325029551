import React from 'react';
import { Typography } from '@material-ui/core';

import './sidebar.css';

import MobileDownload from '../../../assets/images/download.png';
import SokoDownload from '../../../assets/images/soko-download.png';
import AppStoreDownload from '../../../assets/images/apple-download.png';
import PlayStoreDownload from '../../../assets/images/google-download.png';
import { IS_SOKO_APP } from '../../../utils/consts';

const SidebarDownload = () => {
  return (
    <div className="side-content-download text-center">
      <div className="download-section">
        <Typography variant="h2" style={{ color: '#65c8d9' }}>
          Download the <br />
          mobile app
        </Typography>
        {IS_SOKO_APP ? (
          <h4 style={{ color: 'grey' }}>
            Our mobile app provides
            <br />
            even more ways to engage
            <br />
            with farmers&#39; markets
          </h4>
        ) : (
          <h4 style={{ color: 'lightgrey' }}>
            Our mobile app provides
            <br />
            even more ways to connect
            <br />
            with your communities
          </h4>
        )}
        <img
          className="download-mobile"
          src={IS_SOKO_APP ? SokoDownload : MobileDownload}
          alt="Download Mobile App"
        />
        <div className="download-on-store">
          <a
            href={
              IS_SOKO_APP
                ? 'placeholder-soko-playstore-link'
                : 'https://play.google.com/store/apps/details?id=com.ujama&rdid=com.ujama'
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={PlayStoreDownload} alt="Download on Play Store" />
          </a>
        </div>
        <div className="download-on-store">
          <a
            href={
              IS_SOKO_APP
                ? 'placeholder-soko-applestore-link'
                : 'https://itunes.apple.com/us/app/ujama/id1170321120?ls=1&mt=8'
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={AppStoreDownload} alt="Download on App Store" style={{ marginTop: 0 }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarDownload;
